import React from "react";
import { LogoSm } from "./LogoSm";
import { LogoLg } from "./LogoLg";
import { Link } from "gatsby";

export const Nav = () => {
  return (
    <div className="h-60p md:h-120 pl-6 md:pl-20 container-padding flex items-center  ">
      <Link to={"/"}>
        <LogoSm className={"md:hidden"} />
        <LogoLg className={"hidden md:block"} />
      </Link>
    </div>
  );
};
