import React from "react";

interface HeaderInterface {
    title:string | JSX.Element
    subtitle:string | JSX.Element
    currentPage:string | JSX.Element
    homePage?:boolean | JSX.Element
}



export const Header:React.FC<HeaderInterface> = ({title, subtitle, currentPage, homePage}) => {
    return(<header className="w-full bg-sectionbackground overflow-x-hidden">
    <div className="mb-10  container-padding ">
        {homePage ? ''
        :
        <div className="flex flex-row w-max  mb-12  md:mb-28 ">
            <div className="flex flex-row py-2 md:py-4 mr-3 border-grayCardLine border-b">

                <a href="/" className="text-sm pr-3 border-grayCardLine  w-full text-left border-r font-normal whitespace-nowrap breadcrumb-link">Home</a>

            </div>
            <div className="flex flex-row py-2 md:py-4 ">

                <p className="text-sm font-semibold whitespace-nowrap">{currentPage}</p>
            </div>
        </div>
        }
        <div className=" mx-auto flex flex-col md:flex-row lg:w-3/5 lg:mx-0">
            <div className="flex-1 flex-col items-left ">
                <div className="w-full text-sm">
                
                        <h1>{title}</h1>
                  
                </div>
                <div className="w-full">
              
                        <p className="">{subtitle}</p>
                    
                </div>

            </div>

        </div>
    </div>
    <div className=" flex flex-row items-end md:h-32 py-5 mx-auto w-full overflow-x-hidden  mb-12 ">
        <div className=" ml-auto w-full absolute over md:right-0">
            <svg width="100%" height="auto" viewBox="0 0 1442 128" fill="none" xmlns="http://www.w3.org/2000/svg"
                className="text-header-line-svg">
                <path d=" M1911 -147.5C1727 -70.5 1166.5 102.761 1078.48 102.761C810.275 102.761 1083.37 -102.643
                1039.94 91.7865C1017 194.5 210 17 -519.5 67.5" stroke="#00F200" stroke-width="6" stroke-linecap="round"
                    id="text-header-line-svg" />
            </svg>
        </div>
    </div>
</header>)
}