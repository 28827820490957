import React from "react"

interface LogoInterface {
    className:string
}

export const LogoSm:React.FC<LogoInterface> = ({className}) => {
    return(
        <svg width="74" height="23" viewBox="0 0 74 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
<g clip-path="url(#clip0)">
<path d="M14.6729 15.101H9.00096L5.05522 10.4733H4.74696V15.101H-0.000244141V0H8.32278C9.98739 0 11.3437 0.48713 12.3302 1.4005C13.3783 2.37476 13.8715 3.59258 13.8715 5.17576C13.8715 6.33268 13.5632 7.36784 12.9467 8.2812C12.3302 9.13368 11.467 9.7426 10.3573 10.1688L14.6729 15.101ZM4.74696 3.77526V6.81982H7.33635C7.95287 6.81982 8.38444 6.69802 8.75436 6.39358C9.12426 6.08912 9.24757 5.72378 9.24757 5.23664C9.24757 4.8104 9.06261 4.44506 8.75436 4.1406C8.38444 3.89704 7.95287 3.71436 7.33635 3.71436H4.74696V3.77526Z" fill="#181818"/>
<path d="M28.21 11.2649V15.101H15.8795V0H28.0867V3.83614H20.6267V5.78466H27.4701V9.25546H20.6267V11.204H28.21V11.2649Z" fill="#181818"/>
<path d="M46.274 0L39.9239 15.101H35.3L28.9497 0H34.1901L37.6427 8.52476L41.0335 0H46.274Z" fill="#181818"/>
<path d="M52.0077 15.101H47.2605V0H52.0077V15.101Z" fill="#181818"/>
<path d="M67.5441 3.95792H62.7967V15.101H58.0495V3.95792H53.3024V0H67.4824V3.95792H67.5441Z" fill="#181818"/>
<path d="M73.6476 15.101H68.9621V0H73.7093V15.101H73.6476Z" fill="#181818"/>
<path d="M30.0245 18.8184H0V23.1417H30.0245V18.8184Z" fill="#00F200"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="74" height="23" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}