import * as React from "react";
import { FAQItem } from "../components/FAQItem";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";


// markup
const IndexPage = () => {
  return (
    <Layout title={"Reviti - Home"}>
      <Header title={"Terms and conditions for Reviti’s Amazon.co.uk Gift Card* offer"} subtitle={""} currentPage={"Amazon Incentive"}/>
      <div className="container-padding relative flex flex-col md:flex-row my-30 terms-container"> 
<div className={"w-full md:w-2/3 flex flex-col"}>
      <p>
        <span className="article-section-heading">
          About the Offer (the “Offer”)
        </span>
      </p>
      <p>
        These terms and conditions apply to the Amazon.co.uk Gift Card (the “
        <strong>Gift Card</strong>”) offered to you as a new 
        <strong>Reviti</strong> Term Life policyholder when you take out a new 
        <strong>Reviti</strong> Term Life policy with a minimum monthly premium
        of £5.
      </p>
      <p>
        By taking out a new <strong>Reviti</strong> Term Life policy during the
        Offer Period (as defined below) you are deemed to have accepted these
        terms and conditions.
      </p>
      <p className="p1">
        Please read these terms and conditions carefully before deciding to
        proceed.
      </p>
      <p className="p1">
        The Offer is being provided by <strong>Reviti</strong>, the trading name
        of <strong>Reviti</strong> Limited.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Gift Card Amount</span>
      </p>
      <p>
        The amount of the Gift Card will depend on the how much your monthly
        premium is.
      </p>
      <table
        // border="2"
        // cellspacing="0"
        // cellpadding="5"
        style={{
          height: 90,
         width: "100%",
         borderCollapse: "collapse",
         borderStyle: "solid",
         marginLeft: "auto",
         marginRight: "auto",
         borderColor: "#000000",
        }}
      >
        <tbody>
          <tr style={{height: 18}}>
            <td style={{width: "50%", height: 18}}>
              <strong>Monthly Premium</strong>
            </td>
            <td style={{width: "50%", height: 18}}>
              <strong>Gift Card Amount</strong>
            </td>
          </tr>
          <tr style={{height: 18}}>
            <td style={{width: "50%", height: 18}}>£5-£9.99</td>
            <td style={{width: "50%", height: 18}}>£40</td>
          </tr>
          <tr style={{height: 18}}>
            <td style={{width: "50%", height: 18}}>£10 to £14.99</td>
            <td style={{width: "50%", height: 18}}>£75</td>
          </tr>
          <tr style={{height: 18}}>
            <td style={{width: "50%", height: 18}}>£15 to £19.99</td>
            <td style={{width: "50%", height: 18}}>£100</td>
          </tr>
          <tr style={{height: 18}}>
            <td style={{width: "50%", height: 18}}>£20 +</td>
            <td style={{width: "50%", height: 18}}>£125</td>
          </tr>
        </tbody>
      </table>
      <p> </p>
      <p>
        <span className="article-section-heading">
          Qualification for the Offer
        </span>
      </p>
      <ol>
        <li>
          <span className="Apple-converted-space">
            The Offer is only open to UK residents who are aged 18 years and
            above who purchase a new <strong>Reviti</strong> Term Life policy
            online at 
            <a
              href="/{localLink:umb://document/faf680961f9648e2b33add3293e6846c}"
              title="Reviti Life Insurance"
            >
              www.revitilife.com
            </a>
             or through the <strong>Reviti</strong> contact centre between 00:01
            GMT on 28 October 2021 and 23:59 GMT on 10th December  2021 (the
            “Offer Period”). Purchases outside the Offer Period are not eligible
            for this Offer.
            <br />
            <br />
          </span>
        </li>
        <li>
          <span className="Apple-converted-space">
            The Offer applies to new customers only. You will not receive a Gift
            Card if you currently have or have previously had a 
            <strong>Reviti</strong> Term Life policy.{" "}
          </span>
          <br />
          <br />
        </li>
        <li>
          Your policy must have been in force for 6 months and you must have
          paid all your monthly premiums in full when they were due. No Gift
          Card will be issued if you cancel your policy within the first 6
          months or you miss a monthly payment.
          <br />
          <br />
        </li>
        <li>
          Only one Gift Card will be distributed per <strong>Reviti</strong>
           policyholder, limited to two Gift Cards per postal address. If you
          purchase multiple <strong>Reviti</strong> Term Life policies during
          the Offer Period the Offer will be applicable only in respect of the
          first <strong>Reviti</strong> Term Life policy taken out in your name
          during the Offer Period.
        </li>
      </ol>
      <p> </p>
      <p>
        <span className="article-section-heading">
          Receiving your Gift Card
        </span>
      </p>
      <p>
        Qualifying policyholders will be sent an email with a 16 digit claim
        code. You will have to add this code to your Amazon.co.uk account to
        start shopping. <strong>Reviti</strong> will email the code to you
        within 30 days of your <strong>Reviti</strong> Term Life policy being in
        force for 6 months.
      </p>
      <p>
        Please note the code will be sent to the email address held on our
        records. If you change any of your contact details, including your email
        address or postal address you must notify Scottish Friendly (telephone
        0333 3235433) to ensure that the code is sent to the correct email
        address. If you don't receive the email within 30 days following your{" "}
        <strong>Reviti</strong> Term Life policy being in force for 6 months,
        then please inform Customer Support at{" "}
        <a
          rel="noopener"
          href="mailto:support@revitilife.com"
          target="_blank"
          title="Support email address"
        >
          support@revitilife.com
        </a>
         for assistance.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">General</span>
      </p>
      <ol>
        <li>
          <strong>Reviti</strong> will not be liable to compensate you if you
          are unable to benefit from this promotion because your application for
          a <strong>Reviti</strong> Term Life policy is declined.
        </li>
        <li>
          <p className="p1">
            The Gift Card is non-exchangeable and there is no cash alternative.
            Please note that Amazon.co.uk is not a sponsor of this promotion.
            Gift Cards may be redeemed on the Amazon.co.uk website by adding
            your claim code and used towards the purchase of products available
            on{" "}
            <a
              rel="noopener"
              href="http://www.amazon.co.uk"
              target="_blank"
              title="Amazon website"
            >
              www.amazon.co.uk
            </a>
            .
          </p>
        </li>
        <li>
          Gift Cards cannot be reloaded, resold, transferred for value, redeemed
          for cash or applied to any other account. Amazon.co.uk is not
          responsible if a Gift Card is lost, stolen, destroyed or used without
          permission. The Amazon.co.uk Gift Card is subject to Amazon.co.uk’s
          own Gift Card terms and conditions. See 
          <a
            rel="noopener"
            href="http://www.amazon.co.uk/gc-legal"
            target="_blank"
            title="Amazon legal information"
          >
            www.amazon.co.uk/gc-legal
          </a>
           for complete terms and conditions. Gift Cards are issued by Amazon EU
          S.à r.l, a Luxembourg entity. All Amazon ®, ™ &amp; © are IP of
          Amazon.com, Inc. or its affiliates.
          <br />
          <br />
        </li>
        <li>
          The Offer is not sponsored by, endorsed or administered by, or
          associated with, Amazon.
          <br />
          <br />
        </li>
        <li>
          <strong>Reviti</strong> reserves the right at its sole discretion, to
          cancel or modify this Offer and/or Terms and Conditions at any time,
          for any reason and without prior notice or explanation. This includes
          terminating the Offer Period early.
          <br />
          <br />
        </li>
        <li>
          <strong>Reviti</strong> accepts no responsibility for any loss,
          damages, liabilities or disappointment incurred or suffered by you as
          a result of you participating in the Offer. <strong>Reviti</strong>
           further disclaims liability for any damage to your or any other
          person's computer relating to or resulting from participation in or
          downloading any materials in connection with the Offer.
          <br />
          <br />
        </li>
        <li>
          <strong>Reviti</strong> shall not be liable for any failure to comply
          with its obligations where the failure is caused by something outside
          its reasonable control.
          <br />
          <br />
        </li>
        <li>
          These terms and conditions shall be governed by and construed in
          accordance with the laws of England and Wales. The Courts of England
          and Wales shall have exclusive jurisdiction in connection with these
          terms and conditions.
        </li>
      </ol>
      <p> </p>
      <p>
        * Restrictions apply, see{" "}
        <a
          rel="noopener"
          href="http://www.amazon.co.uk/gc-legal"
          target="_blank"
          title="Amazon legal information"
        >
          www.amazon.co.uk/gc-legal
        </a>
      </p>
      </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
