import { Link } from "gatsby";
import React from "react";
import { LogoSm } from "./LogoSm";

const links1 = [
  {
    label: "Contact Us",
    url: "/contact-us",
  },
  {
    label: "Terms of use",
    url: "/terms-of-use",
  },
  {
    label: "Privacy Notice",
    url: "/privacy-notice",
  },
];

const links2 = [
  {
    label: "Cookie Preferences",
    url: "/",
  },
  {
    label: "Reviti Payback T&C's",
    url: "/reviti-payback-terms-and-conditions",
  },
  {
    label: "Amazon Voucher T&C's",
    url: "/amazon_incentive",
  },
];

export const Footer = () => {
  return (
    <footer className=" w-full mx-auto flex pb-50 flex-col container-padding">
      <div className="top py-60 ">
        <div className="w-full md:1/2">
          <div className="logo-container w-24 flex justify-left">
            <LogoSm className={""} />
          </div>

          {/* <div className="pb-30 border-b border-keyline lg:border-0">
            <div className="social-rules">@rulesOfEngagement</div>
          </div> */}
        </div>
        <div className="grid md:grid-cols-2 w-1/2 pt-10">
          <ul className="">
            {links1.map((v) => {
              return (
                <li className="">
                  <Link className="text-sm font-bold underline pb-4" to={v.url}>
                    {v.label}
                  </Link>
                </li>
              );
            })}
          </ul>

          <ul className="">
            {links2.map((x) => {
              if (x.label == "Cookie Preferences") {
                return (
                  <li className="ml-0  pt-1">
                    <p id={"ot-sdk-btn"} className="ot-sdk-show-settings pb-1 text-sm font-bold underline cursor-pointer overwrite-cookie-styling">
                      {x.label}
                    </p>
                  </li>
                );
              } else {
                return (
                  <li className="ml-0">
                    <Link
                      className="text-sm font-bold underline pb-4 "
                      to={x.url}
                    >
                      {x.label}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>

        {/* <div className="flex lg:hidden footer-mobile" x-data="{open: open}">
        <ul x-cloak className=" z-50 w-full font-mono bg-white" x-transition:enter="transition ease-out duration-300"
            x-transition:enter-start="opacity-0  transform -translate-y-2"
            x-transition:enter-end="opacity-100 transform translate-y-0"
            x-transition:leave="transition ease-in duration-100"
            x-transition:leave-end="opacity-0 transform -translate-y-3">
            @if (lifeInsurance != null)
            {
                <li className="flex flex-col mx-auto border-keyline border-b cursor-pointer" x-data="{ dropdown: false }">
                    <div className="flex flex-row align-center items-center justify-between py-4"
                         @@click="dropdown = !dropdown">
                        <p className="cursor-default">Life Insurance</p>
                        <img src="~/static/icons/arrow-down.svg" alt="arrow-down" className='h-3' />
                    </div>



                    <div x-show="dropdown">
                        @foreach (var x in lifeInsurance)
                        {
                            <div className="flex flex-row  py-2 items-center">
                                <div className='w-5 h-0.5 bg-primaryblack mr-4'></div>
                                <a href="@x.NavUrl.Url" className='text-lg font-normal font-mono'> @x.Label </a>
                            </div>
                        }
                    </div>


                </li>
            }
            @if (aboutReviti != null)
            {
                <li className="flex flex-col mx-auto border-keyline border-b cursor-pointer" x-data="{ dropdown: false }">
                    <div className="flex flex-row align-center items-center justify-between py-4"
                         @@click="dropdown = !dropdown">
                        <p className="cursor-default">About Reviti</p>
                        <img src="~/static/icons/arrow-down.svg" alt="arrow-down" className='h-3' />
                    </div>



                    <div x-show="dropdown">
                        @foreach (var x in aboutReviti)
                        {
                            if (x.Label == "Cookie Preferences")
                            {

                                <div className="flex flex-row  py-2 items-center">
                                    <div className='w-5 h-0.5 bg-primaryblack mr-4'></div>
                                    <p className='ot-sdk-show-settings text-lg font-normal font-mono'>
                                        Cookie Preferences
                                    </p>
                                </div>
                            }
                            else
                            {
                                <div className="flex flex-row  py-2 items-center">
                                    <div className='w-5 h-0.5 bg-primaryblack mr-4'></div>
                                    <a href="@x.NavUrl.Url" className='text-lg font-normal font-mono'> @x.Label </a>
                                </div>
                            }
                        }
                    </div>


                </li>
            }
        </ul> */}

        {/* </div> */}
      </div>

      <div className="bottom-0 flex flex-col border-t-2 border-black pt-8 footer_legal">
        <h3 className="text-sm font-semibold">
          Copyright © Reviti Limited 2022
        </h3>
        <p>
          <strong>Reviti</strong> is the trading name of <strong>Reviti</strong> Limited. <strong>Reviti</strong> Limited (<strong>“Reviti”</strong>) is not an insurance company. <strong>REVITI</strong> is a registered trademark owned by Philip Morris Products S.A., used under license by <strong>Reviti</strong> Limited. Scottish Friendly Assurance Society Limited (Scottish Friendly) is the underwriter of <strong>Reviti</strong> Term Life Insurance. The registered office of <strong>Reviti</strong> is One New Change, 4th Floor, London, EC4M 9AF, United Kingdom. Company number 11450807.
          Scottish Friendly Assurance Society Limited (no. SP00034F) is authorised by the Prudential Regulatory Authority and regulated by the Financial Conduct Authority and Prudential Regulatory Authority (FCA no. 110002). Its registered office address is Scottish Friendly House, 16 Blythswood Square, Glasgow, Lanarkshire, G2 4HJ, United Kingdom.
          Prior to 31st January 2022, <strong>Reviti</strong> Limited was an Appointed Representative of Resolution Compliance Limited which is authorised and regulated by the Financial Conduct Authority (Financial Services Register number: 574048).
        </p>
        {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings w-full">
          Cookie Settings
        </button> */}
      </div>
    </footer>
  );
};
