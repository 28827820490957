import React, { Children } from "react"
import { Helmet } from "react-helmet"
import { Footer } from "./Footer"
import { Nav } from "./Nav"


interface LayoutInterface {
    title: string
}

export const Layout:React.FC<LayoutInterface> = ({title,children}) => {
    return(
        <div>
<Helmet>
 
    <title>{title}</title>
   
</Helmet>
    <noscript><iframe src="https://www.g.com/ns.html?id=GTM-W6LT4CM"
    height="0" width="0" style={{display:"none", visibility:"hidden"}}>
        </iframe></noscript>
     {/* Nav */}
     <Nav/>
    <div className="w-full ">
    {/* Body */}
    {children}
    </div>
    {/* Footer */}
    <Footer/>
    <script src="~/lib/jquery/dist/jquery.min.js"></script>
    <script src="~/js/site.js" asp-append-version="true"></script>
    <script src="https://ajax.aspnetcdn.com/ajax/jQuery/jquery-3.0.0.min.js"></script>
    <script src="https://ajax.aspnetcdn.com/ajax/jquery.validate/1.16.0/jquery.validate.min.js"></script>
    <script src="https://ajax.aspnetcdn.com/ajax/mvc/5.2.3/jquery.validate.unobtrusive.min.js"></script>   
        </div>
    )
}